import axios from "axios";
import Constant from "@/utils/constants";
export async function getAllJuz() {
  var listJuz;
  const response = await axios

    .get(Constant.QORANI + "juz/getall")
    .then((response) => {
      listJuz = response.data;
    });

  return listJuz;
}
export async function getAllHizb() {
  var listHizb;
  const response = await axios
    .get(Constant.QORANI + "hizb/getall")
    .then((response) => {
      listHizb = response.data;
    });

  return listHizb;
}
export async function getAllSurat() {
  var listSurat;
  const response = await axios
    .get(Constant.QORANI + "surat/getall")
    .then((response) => {
      listSurat = response.data;
    });

  return listSurat;
}
export async function getAllTheme() {
  var listTheme;
  const response = await axios
    .get(Constant.QORANI + "library/category/getall")
    .then((response) => {
      listTheme = response.data;
    });

  return listTheme;
}
export async function getAllVerset() {
  var listVerset;
  const response = await axios
    .get(Constant.QORANI + "verset/getall")
    .then((response) => {
      listVerset = response.data;
    });

  return listVerset;
}
export async function getAllVersetWithPagination(page, size) {
  const body = {};
  body.page = page;
  body.size = size;
  const response = await fetch(Constant.QORANI + "verset/getallPaginateAdmin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return response.json();
}
export async function getAllVersetBySurate(SuratId) {
  try {
    const response = await axios.get(
      `${Constant.QORANI}verset/getBySurateAdmin/${SuratId}`
    );
    const listVersetBySurate = response.data;
    return listVersetBySurate;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}

export async function getListVersetStartTime(recitateurId, listVersetId) {
  try {
    const response = await axios.get(
      `${Constant.QORANI}verset/getBySurateAdmin/${SuratId}`
    );
    const listVersetBySurate = response.data;
    return listVersetBySurate;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}

export async function getAllJuzz() {
  var listJuzz;
  const response = await axios
    .get(Constant.QORANI + "juz/getall")
    .then((response) => {
      listJuzz = response.data;
    });

  return listJuzz;
}
export async function getAllProphete() {
  var listProphete;
  const response = await axios
    .get(Constant.QORANI + "prophete/getall")
    .then((response) => {
      listProphete = response.data;
    });

  return listProphete;
}
export async function getAllRecitateur() {
  var listRecitateur;
  const response = await axios
    .get(Constant.QORANI + "recitateur/getall")
    .then((response) => {
      listRecitateur = response.data;
    });
  return listRecitateur;
}
export async function getAllActualityCategory() {
  var listActualityCategory;
  const response = await axios
    .get(Constant.QORANI + "actualityCategory/getall")
    .then((response) => {
      listActualityCategory = response.data;
    });

  return listActualityCategory;
}
export async function getAllActuality() {
  var listActuality;
  const response = await axios
    .get(Constant.QORANI + "actuality/getall")
    .then((response) => {
      listActuality = response.data;
    });

  return listActuality;
}

export async function getAllActualityDescItem(id) {
  var listActualityDescItem;
  const response = await axios
    .get(Constant.QORANI + "actuality/get/" + id)
    .then((response) => {
      listActualityDescItem = response.data;
    });

  return listActualityDescItem.actualityDescItemResponseList;
}
export async function getAllUserstByRole(Role, page) {
  try {
    const response = await axios.get(
      `${Constant.QORANI}user/getByRole/${Role}/${page}`
    );
    const listUserstByRole = response.data;
    return listUserstByRole;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}
export async function getAllVersetGroupe() {
  var VersetGroupe;
  const response = await axios
    .get(Constant.QORANI + "versetGroup/getall")
    .then((response) => {
      VersetGroupe = response.data;
    });

  return VersetGroupe;
}
export async function getVersetGroupeById(VersetGroupeId) {
  try {
    const response = await axios.get(
      `${Constant.QORANI}versetGroup/get/${VersetGroupeId}`
    );
    const VersetGroupe = response.data;
    return VersetGroupe;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}
export async function getSouratById(SouratId) {
  try {
    const response = await axios.get(`${Constant.QORANI}surat/get/${SouratId}`);
    const surat = response.data;
    return surat;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}
export async function getPropheteNameById(id, PropheteArray) {
  return PropheteArray.find((prophete) => prophete.id === id);
}
export async function SearchByKeyWord(key) {
  try {
    const response = await axios.get(
      `${Constant.QORANI}user/findByKeyword/${key}`
    );
    const listUserstByRole = response.data;

    return listUserstByRole;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}
