<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-4 ">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title>Modification Verset</v-card-title>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-textarea
                    v-model="versetInfo.arabic_text"
                    name="input-7-1"
                    variant="filled"
                    label="آية"
                    auto-grow
                    outlined
                    :rules="rule"
                    required
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-textarea
                    v-model="versetInfo.french_text"
                    name="input-7-1"
                    variant="filled"
                    label="verset"
                    auto-grow
                    :rules="rule"
                    required
                    outlined
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="versetInfo.phonetic"
                    name="input-7-1"
                    variant="filled"
                    label="Phonétique"
                    auto-grow
                    :rules="rule"
                    required
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <VueEditor
                    v-model="versetInfo.tafsyr"
                    auto-grow
                    outlined
                    label="Tafsyr"
                    :rules="rule"
                    placeholder="Tafsyr"
                  >
                  </VueEditor>
                </v-col>
                <v-col cols="12" sm="6" md="3" xs="12">
                  <v-text-field
                    v-model="versetInfo.verse_number"
                    hide-details
                    outlined
                    type="number"
                    oninput="if(this.value < 1) this.value = 1;"
                    label="Numero verset"
                    :rules="rule"
                  />
                </v-col>

                <!--<v-col cols="12" sm="6" md="3" xs="12">
                  <v-select
                    v-model="versetInfo.themeId"
                    clearable
                    label="Theme"
                    item-text="title"
                    item-value="id"
                    outlined
                    :items="themeArray"
                  ></v-select>
                </v-col> -->

                <v-col cols="12" sm="6" md="3" xs="12">
                  <v-select
                    v-model="versetInfo.hizbId"
                    clearable
                    label="Hizb"
                    item-text="name"
                    item-value="hizbId"
                    outlined
                    :items="hizbArray"
                    :rules="rule"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" xs="12">
                  <v-select
                    v-model="versetInfo.suratId"
                    :items="suratArray"
                    clearable
                    label="Surat"
                    item-text="frenchName"
                    item-value="suratId"
                    :rules="rule"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-row-reverse">
                    <v-btn class=" ml-6 mt-4" color="info" @click="validate">
                      Enregistrer
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-card class="mb-6 ml-6 mt-6 mr-2">
      <v-card-title> Liste Tafsir Par Sous Themes</v-card-title>
      <v-card-title>
        <v-btn color="info darken-1 " @click="isVersetHasGroupe()">
          Ajouter
        </v-btn>
      </v-card-title>
      <v-data-table
        :loading="isLoadingTables"
        loading-text="Loading..."
        :footer-props="{
          'items-per-page-options': [5, 10, 15, 30],
        }"
        :headers="headers"
        :items="versetInfo.listPropheteResponseList"
        class="elevation-1"
      >
        <template v-slot:item.propheteId="{ item }">
          {{ item.propheteName.name }}
        </template>
        <template v-slot:item.versetGroupId="{ item }">
          Tafsir Depuis le Verset
          <a style="color: gray;">
            <strong>{{ item.versetGroup.verset_start }} </strong></a
          >
          à Verset
          <a style="color: gray;">
            <strong>{{ item.versetGroup.verset_end }} </strong></a
          >
        </template>
        <template v-slot:item.supprimer="{ item }">
          <v-icon @click="suppressionAction(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
        <template v-slot:item.modifier="{ item }">
          <v-icon @click="redirectToEditAction(item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card> -->
    <v-row justify="center">
      <v-dialog v-model="dialogEditVerset" max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir Modifier le verset ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogEditVerset = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="EditVerset()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogDeletePropheteVerset" max-width="530">
        <v-card>
          <v-card-title class="text-h6 text-center">
            Etes vous sur de vouloir supprimer le sous theme
            {{ nameDeleteProphete }} du Verset ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogDeletePropheteVerset = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="deletePropheteVerset()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogEditTafsir" max-width="800">
        <v-card>
          <v-card-title style="text-align: center;" class="justify-center mb-2">
            Modification Tafsir Du Verset Pour Le Sous Theme
            {{ this.EditTafsirPropheteName.name }}
          </v-card-title>

          <v-col cols="12" sm="12" md="12"> </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="error" text @click="dialogEditTafsir = false">
              Annuler
            </v-btn>

            <v-btn
              color="info"
              @click="dialogEditConfirmePropheteVerset = true"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogEditConfirmePropheteVerset" max-width="530">
        <v-card>
          <v-card-title class="text-h6 text-center">
            Etes vous sur de vouloir Modifier Tafsir?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogDeletePropheteVerset = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="UpdatePropheteVerset()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--dialogAddGroupe-->
    <v-row justify="center">
      <v-dialog v-model="dialogAddGroupe" max-width="600">
        <v-card>
          <v-card-title style="text-align: center;" class="justify-center mb-2">
            Cree un Groupe verst
          </v-card-title>
          <v-card-title>
            Sélectionner le début et la fin du verset Groupe
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
                xs="6"
                v-if="versetInfo.themeId == null"
              >
                <v-select
                  v-model="themeTafsir"
                  clearable
                  label="Theme"
                  :items="themeArray"
                  item-text="title"
                  item-value="id"
                  outlined
                  @change="getListProphete(themeTafsir)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="12">
                <v-select
                  v-model="SousthemeTafsir"
                  clearable
                  label="Sous Theme"
                  :items="filteredProphets"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-col v-if="surat" style="padding: 25px;">
            <v-range-slider
              v-model="range"
              :max="surat === null ? 1 : surat.versetNumber"
              :min="min"
              hide-details
              class="align-center"
            >
              <template v-slot:prepend>
                <v-text-field
                  :value="range[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(range, 0, $event)"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  :value="range[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(range, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-col>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogAddGroupe = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="AddGroupeVerset()">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--dialogSelectGroupe-->
    <v-row justify="center">
      <v-dialog v-model="dialogSelectGroupe" max-width="800">
        <v-card>
          <v-card-title style="text-align: center;" class="justify-center mb-2">
            Sélectionner un Groupe Verset
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
                xs="6"
                v-if="versetInfo.themeId == null"
              >
                <v-select
                  v-model="themeTafsir"
                  clearable
                  label="Theme"
                  :items="themeArray"
                  item-text="title"
                  item-value="id"
                  outlined
                  @change="getListProphete(themeTafsir)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="12">
                <v-select
                  v-model="SousthemeTafsir"
                  clearable
                  label="Sous Theme"
                  :items="filteredProphets"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-col cols="12" sm="12" md="12">
            <v-radio-group
              v-model="selectedGroupeValue"
              label="Select group Verset"
            >
              <v-radio
                v-for="item in radioOptions"
                :key="item.versetGroupId || index"
                :label="generateLabel(item)"
                :value="item.versetGroupId"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-card-title style="text-align: center;" class="justify-center mb-2">
            <v-btn color="info" @click="CreatNewGrouope()">
              Cree un Groupe verst</v-btn
            >
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
              @click="dialogSelectGroupe = false"
            >
              Annuler
            </v-btn>

            <v-btn
              :disabled="selectedGroupeValue === null"
              color="info darken-1 "
              text
              @click="SelectGroupeVerset()"
            >
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>
<script>
import axios from "axios";
import Constant from "@/utils/constants";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import { mdiPlus, mdiDeleteOutline, mdiPencil } from "@mdi/js";

import {
  getAllHizb,
  getAllSurat,
  getAllCategory,
  getAllProphete,
  getVersetGroupeById,
  getSouratById,
} from "@/views/qorani/services/services";
export default {
  components: { VueEditor },
  data() {
    return {
      themeTafsir: null,
      surat: [],
      dialogEditConfirmePropheteVerset: false,
      dialogDeletePropheteVerset: false,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiPencil,
      },
      headers: [
        { text: "Sous Theme", value: "propheteId" },
        { text: "verset Group", value: "versetGroupId", align: "center" },
        { text: "Supprimer", value: "supprimer", align: "center" },
      ],
      dialogEditVerset: false,
      PropheteArray: [],
      valid: true,
      images: [],
      isLoadingTables: false,
      versetInfo: {
        versetId: "",
        arabic_text: "",
        french_text: "",
        phonetic: "",
        verse_number: "",
        tafsyr: "",
        suratRank: "",
        suratId: "",
        themeId: "",
        hizbId: "",
      },
      idDeleteProphete: null,
      nameDeleteProphete: "",
      themeArray: [],
      hizbArray: [],
      suratArray: [],
      EditTafsirPropheteVerset: [],
      dialogEditTafsir: false,
      EditTafsirPropheteName: [],
      rule: [(v) => !!v || "Ce champ est requis"],
      dialogSelectGroupe: false,
      radioOptions: [],
      dialogAddGroupe: false,
      selectedGroupeValue: null,
      min: 1,
      max: 800,
      range: [0, 1],
      SousthemeTafsir: null,
      PropheteToVerset: {
        versetId: "",
        propheteId: "",

        versetGroupId: "",
      },
      versetGroup: {
        suratId: "",
        verset_start: "",
        verset_end: "",
      },
      filteredProphets: [],
    };
  },

  methods: {
    async AddGroupeVerset() {
      this.versetGroup.suratId = this.versetInfo.suratId;
      this.versetGroup.verset_start = this.range[0];
      this.versetGroup.verset_end = this.range[1];

      try {
        // Add versetGroup
        const response1 = await fetch(Constant.QORANI + "versetGroup/add", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.versetGroup),
        });

        if (!response1.ok) {
          throw new Error("Error adding versetGroup");
        }

        const data1 = await response1.json();

        if (this.themeTafsir) {
          this.versetInfo.themeId = this.themeTafsir;

          try {
            const responseUPDATE = await fetch(
              Constant.QORANI + "verset/edit/" + this.versetInfo.versetId,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.versetInfo),
              }
            );

            if (!responseUPDATE.ok) {
              throw new Error("Error updating the verse.");
            }

            // Add PropheteToVerset
            this.PropheteToVerset.versetGroupId = data1.versetGroupId;
            this.PropheteToVerset.versetId = this.$route.params.versetId;
            this.PropheteToVerset.propheteId = this.SousthemeTafsir;

            try {
              const response2 = await fetch(
                Constant.QORANI + "verset/addPropheteToVerset",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(this.PropheteToVerset),
                }
              );

              if (!response2.ok) {
                throw new Error("Error adding PropheteToVerset.");
              }
              if (response2.ok) {
                window.location.reload();
              }
            } catch (error) {
              alert(
                "Une erreur est survenue lors de l'ajout du verset, veuillez réessayer plus tard. Merci."
              );
            }
          } catch (error) {
            alert(
              "Une erreur est survenue lors de l'ajout de la surat, veuillez réessayez plus tard, Merci."
            );
          }
        } else {
          this.PropheteToVerset.versetGroupId = data1.versetGroupId;
          this.PropheteToVerset.versetId = this.$route.params.versetId;
          this.PropheteToVerset.propheteId = this.SousthemeTafsir;

          try {
            const response2 = await fetch(
              Constant.QORANI + "verset/addPropheteToVerset",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.PropheteToVerset),
              }
            );

            if (!response2.ok) {
              throw new Error("Error adding PropheteToVerset.");
            }
            if (response2.ok) {
              window.location.reload();
            }
          } catch (error) {
            alert(
              "Une erreur est survenue lors de l'ajout du verset, veuillez réessayer plus tard. Merci."
            );
          }
        }

        // Reload the page or handle success
        window.location.reload();
      } catch (error) {
        console.error("An error occurred:", error);
        alert(
          "Une erreur est survenue lors de l'ajout du verset, veuillez réessayer plus tard. Merci."
        );
      }
    },
    async SelectGroupeVerset() {
      if (this.themeTafsir) {
        this.versetInfo.themeId = this.themeTafsir;

        try {
          const responseUPDATE = await fetch(
            Constant.QORANI + "verset/edit/" + this.versetInfo.versetId,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(this.versetInfo),
            }
          );

          if (!responseUPDATE.ok) {
            throw new Error("Error updating the verse.");
          }

          // Add PropheteToVerset
          this.PropheteToVerset.versetGroupId = this.selectedGroupeValue;
          this.PropheteToVerset.versetId = this.$route.params.versetId;
          this.PropheteToVerset.propheteId = this.SousthemeTafsir;

          try {
            const response2 = await fetch(
              Constant.QORANI + "verset/addPropheteToVerset",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.PropheteToVerset),
              }
            );

            if (!response2.ok) {
              throw new Error("Error adding PropheteToVerset.");
            }
            if (response2.ok) {
              window.location.reload();
            }
          } catch (error) {
            alert(
              "Une erreur est survenue lors de l'ajout du verset, veuillez réessayer plus tard. Merci."
            );
          }
        } catch (error) {
          alert(
            "Une erreur est survenue lors de l'ajout de la surat, veuillez réessayez plus tard, Merci."
          );
        }
      } else {
        this.PropheteToVerset.versetGroupId = this.selectedGroupeValue;
        this.PropheteToVerset.versetId = this.$route.params.versetId;
        this.PropheteToVerset.propheteId = this.SousthemeTafsir;

        try {
          const response2 = await fetch(
            Constant.QORANI + "verset/addPropheteToVerset",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(this.PropheteToVerset),
            }
          );

          if (!response2.ok) {
            throw new Error("Error adding PropheteToVerset.");
          }
          if (response2.ok) {
            window.location.reload();
          }
        } catch (error) {
          alert(
            "Une erreur est survenue lors de l'ajout du verset, veuillez réessayer plus tard. Merci."
          );
        }
      }
    },
    async getListProphete(theme) {
      if (theme === null) {
        this.filteredProphets = [];
        return;
      }
      this.filteredProphets = this.PropheteArray.filter((prophet) =>
        prophet.listThemeId.includes(theme)
      );
    },
    CreatNewGrouope() {
      this.dialogSelectGroupe = false;
      this.dialogAddGroupe = true;
    },
    generateLabel(item) {
      return `Depuis le Verst ${item.verset_start} à Verst ${item.verset_end}`;
    },
    async isVersetHasGroupe() {
      this.surat = await getSouratById(this.versetInfo.suratId);
      try {
        const response = await fetch(
          Constant.QORANI +
            `versetGroup/isVersetHasGroupe/${this.$route.params.versetId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        if (data.length > 0) {
          const uniqueSet = new Set(data.map((item) => item.versetGroupId));
          const uniqueData = Array.from(uniqueSet).map((versetGroupId) => {
            return data.find((item) => item.versetGroupId === versetGroupId);
          });

          this.dialogSelectGroupe = true;
          this.radioOptions = uniqueData;
        } else {
          this.dialogAddGroupe = true;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async UpdatePropheteVerset() {
      const Edit = {};
      Edit.versetId = this.$route.params.versetId;
      Edit.propheteId = this.EditTafsirPropheteVerset.propheteId;
      Edit.versetGroupId = this.EditTafsirPropheteVerset.versetGroupId;

      const response = await fetch(
        Constant.QORANI + "verset/editPropheteToVerset",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Edit),
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
        );
      }
    },
    redirectToEditAction(item) {
      this.dialogEditTafsir = true;
      this.EditTafsirPropheteName = item.propheteName;
      this.EditTafsirPropheteVerset = item;
    },
    async deletePropheteVerset() {
      const response = await fetch(
        Constant.QORANI +
          `verset/deleteProphete/${this.idDeleteProphete}/FromVersetId/${this.$route.params.versetId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        window.location.reload();
      } catch (error) {
        alert(
          "Une erreur est survenue lors de la suppression du juz, veuillez réessayez plus tard, Merci."
        );
        this.dialogDeletePropheteVerset = false;
      }
    },
    suppressionAction(item) {
      this.idDeleteProphete = item.propheteName.id;
      this.nameDeleteProphete = item.propheteName.name;
      this.dialogDeletePropheteVerset = true;
    },
    async getPropheteNameById(id) {
      return this.PropheteArray.find((prophete) => prophete.id === id);
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.dialogEditVerset = true;
      }
    },
    async EditVerset() {
      const responseCategorie = await fetch(
        Constant.QORANI + "verset/edit/" + this.versetInfo.versetId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.versetInfo),
        }
      );
      if (this.$route.params.complaint) {
        const Complaint = this.$route.params.detailComplaint;
        try {
          const response = await axios.post(
            Constant.QORANI + `reclamation/edit/${Complaint.id}`,
            {
              solve: true,
              reclamation: Complaint.reclamation,
              verset_Id: Complaint.verset_Id,
            }
          );
          this.$router.push({
            name: "Complaint",
          });
        } catch (error) {
          console.error("Error toggling solve status:", error);
        }
      } else {
        try {
          this.$router.push({
            name: "Verset",
          });
        } catch (error) {
          alert(
            "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
          );
        }
      }
    },
  },
  async created() {
    this.isLoadingTables = true;
    try {
      this.PropheteArray = await getAllProphete();
      const versetId = this.$route.params.versetId;
      const response = await axios.get(
        `${Constant.QORANI}verset/get/${versetId}`
      );
      this.versetInfo = response.data;
      const promises = this.versetInfo.listPropheteResponseList.map(
        async (item) => {
          item.propheteName = await this.getPropheteNameById(item.propheteId);
          item.versetGroup = await getVersetGroupeById(item.versetGroupId);
        }
      );

      await Promise.all(promises);

      this.themeArray = await getAllTheme();
      this.hizbArray = await getAllHizb();
      this.suratArray = await getAllSurat();
      this.getListProphete(this.versetInfo.themeId);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
    this.isLoadingTables = false;
  },
};
</script>
<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";
/* Import the Quill styles you want */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>
